.button-primary {
  display: inline-block;
  /* border-radius: var(--button-radius); */
  border-width: 0;
  border-style: solid;
  border-color: var(--button-border-color);
  font-size: var(--text-body-size);
  font-weight: var(--text-button-weight);
  letter-spacing: 1px;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--button-outline-color);
  box-shadow: var(--badge-shadow);
}

.powered-by-button {
  background: var(--bg-footer);
  color: var(--text-color);
  box-shadow: 0 0px 20px hsla(0, 0%, 0%, 0.15);
  border: 1px solid var(--text-color);
}

.social-media-button {
  color: var(--icon-text-color);
  background-color: var(--icon-bg-color);
  border-radius: var(--icon-radius);
  outline-color: var(--icon-outline-color);
  outline-style: solid;
  outline-width: 1px;
  box-shadow: var(--icon-shadow);
}

.button-primary:hover,
.button-primary:focus,
.social-media-button:hover,
.social-media-button:focus {
  box-shadow: none;
  opacity: 90%;
}

.swiper-landing-testimonial-button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
}

.testimonial-card {
  background-color: var(
    --bg-footer,
    var(--bg-testimonial)
  ); /* since bg-testimonial is null by default, use bg-footer until people update their themes */
}

.testimonial-card-quotation-mark {
  color: var(--button-bg-color);
}
